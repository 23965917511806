<template>
  <div class="page-wrapper">
    <!-- Hero -->
    <section id="gplauze" class="hero fluid-container">
      <div class="hero-container max-container">
        <div class="hero-row">
          <div class="hero-col col-left">
            <Logo />
            <h2 class="big-title">
              {{ $t("L'expert en gestion parasitaire") }}
            </h2>
            <p class="regular-text">
              {{ $t("La gestion parasitaire et l'extermination sont notre spécialité depuis plus de 10 ans. Les méthodes utilisées et la rigueur de notre travail est à la base de la satisfaction de nos clients.") }}
            </p>
            <a href="#" class="cta" @click.prevent="togglePanel(!panelIsOpen)" >{{ $t('Demandez une soumission') }}</a>
          </div>
          <div class="hero-col col-right">
            <div class="img-wrapper"><img src="@/assets/img/gestion-parasitaire-lauze.jpg" alt="Gestion parasitaire Lauzé"></div>
          </div>
        </div>
      </div>
      <div class="img-cover"><img src="@/assets/img/gestion-parasitaire-lauze.jpg" alt="Gestion parasitaire Lauzé"></div>
    </section>

    <!-- SubHero -->
    <section id="arguments" class="sub-hero fluid-container">
      <div class="sub-hero-container max-container">
        <div class="sub-hero-row">
          <div class="sub-hero-col">
            <h3 class="medium-title">
              <SvgIcon name="check" />
              <span>{{ $t('Satisfaction garantie') }}</span>
            </h3>
          </div>
          <div class="sub-hero-col">
            <h3 class="medium-title">
              <SvgIcon name="shield" />
              <span>{{ $t('Traitements sécuritaires') }}</span>
            </h3>
          </div>
          <div class="sub-hero-col">
            <h3 class="medium-title">
              <SvgIcon name="magnify" />
              <span>{{ $t('Suivis minutieux') }}</span>
            </h3>
          </div>
          <div class="sub-hero-col">
            <h3 class="medium-title">
              <SvgIcon name="money" />
              <span>{{ $t('Estimation gratuite') }}</span>
            </h3>
          </div>
        </div>
      </div>
    </section>

    <!-- ContentList -->
    <section id="services" class="items-list fluid-container">
      <div class="items-list-container max-container">
        <div class="items-list-row">
          <div class="items-list-col col-left">
            <h2 class="big-title">
              {{ $t("Gardez les visiteurs indésirables à l'extérieur") }}
            </h2>
            <p class="regular-text">
              {{ $t("Lorsqu'un problème parasitaire se présente, nous établissons rapidement un plan d'action. Nous pouvons ensuite mettre en place un programme de prévention qui empêchera le retour d'une situation problématique. Nos méthodes vont au-delà d'une simple extermination.") }}
            </p>
          </div>
          <div class="hero-col col-right">
            <SvgIcon name="bug-red" />
            <a href="#" class="cta" @click.prevent="togglePanel(!panelIsOpen)" >{{ $t('Demandez une soumission') }}</a>
          </div>
        </div>
        <div class="items-list-row">
          <ul>
            <li class="small-title">
              <SvgIcon name="mouse" />
              <span>{{ $t('Contrôle des rongeurs') }}</span>
            </li>
            <li class="small-title">
              <SvgIcon name="bed" />
              <span>{{ $t('Contrôle des punaises de lit') }}</span>
            </li>
            <li class="small-title">
              <SvgIcon name="wasp" />
              <span>{{ $t('Contrôle des guêpes') }}</span>
            </li>
            <li class="small-title">
              <SvgIcon name="pigeon" />
              <span>{{ $t('Contrôle des oiseaux') }}</span>
            </li>
            <li class="small-title">
              <SvgIcon name="fourmis" />
              <span>{{ $t('Contrôle des fourmis') }}</span>
            </li>
            <li class="small-title">
              <SvgIcon name="blatte" />
              <span>{{ $t('Contrôle des blattes') }}</span>
            </li>
            <li class="small-title">
              <SvgIcon name="outside" />
              <span>{{ $t('Prévention extérieure') }}</span>
            </li>
            <li class="small-title">
              <SvgIcon name="time" />
              <span>{{ $t('Protection prolongée') }}</span>
            </li>
            <li class="small-title">
              <SvgIcon name="gaz" />
              <span>{{ $t('Fumigation') }}</span>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <!-- ContentList -->
    <section id="secteurs" class="thumbs-list fluid-container">
      <div class="thumbs-list-container max-container">
        <div class="thumbs-list-row">
          <div class="thumbs-list-col col-left">
            <h2 class="big-title">
              {{ $t('Nos secteurs d’activités') }}
            </h2>
            <p class="regular-text">
              {{ $t("Nous avons une solide expérience dans tous les types d'immeubles et habitations. La clé du succès de notre service est de savoir s'adapter aux environnements dans lesquels nous opérons.") }}
            </p>
          </div>
          <div class="hero-col col-right">
            <SvgIcon name="business-red" />
            <a href="#" class="cta" @click.prevent="togglePanel(!panelIsOpen)" >{{ $t('Demandez une soumission') }}</a>
          </div>
        </div>
        <div class="thumbs-list-row">
          <ul>
            <li>
              <div class="img-wrapper"><img src="@/assets/img/extermination-residentiel.jpg" alt="Gestion parasitaire - Extermination résidentiellerèglent"></div>
              <span class="small-title">{{ $t('Résidentiels') }}</span>
            </li>
            <li>
              <div class="img-wrapper"><img src="@/assets/img/extermination-immobilieres.jpg" alt="Gestion parasitaire - Extermination Immobilier"></div>
              <span class="small-title">{{ $t('Immobilier') }}</span>
            </li>
            <li>
              <div class="img-wrapper"><img src="@/assets/img/extermination-resatauration.jpg" alt="Gestion parasitaire - Extermination Restauration"></div>
              <span class="small-title">{{ $t('Restauration') }}</span>
            </li>
            <li>
              <div class="img-wrapper"><img src="@/assets/img/extermination-commercial.jpg" alt="Gestion parasitaire - Extermination commerciale"></div>
              <span class="small-title">{{ $t('Commercial') }}</span>
            </li>
            <li>
              <div class="img-wrapper"><img src="@/assets/img/extermination-bureaux.jpg" alt="Gestion parasitaire - Extermination Bureaux"></div>
              <span class="small-title">{{ $t('Bureaux') }}</span>
            </li>
            <li>
              <div class="img-wrapper"><img src="@/assets/img/extermination-industriel.jpg" alt="Gestion parasitaire - Extermination Industriel"></div>
              <span class="small-title">{{ $t('Industriel') }}</span>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <!-- Timeline -->
    <section id="approche" class="timeline fluid-container">
      <div class="timeline-container max-container">
        <div class="timeline-row">
          <h2 class="big-title">
            {{ $t('Notre approche') }}
          </h2>
        </div>
        <div class="timeline-row">
          <article class="timeline-col">
            <i class="number">1</i>
            <h3 class="medium-title">{{ $t('Inspection') }}</h3>
            <p class="small-text">
              {{
                $t(
                  "Une inspection bien effectuée est à la base de la réussite d'un traitement ou d'un programme de protection."
                )
              }}
            </p>
          </article>
          <article class="timeline-col">
            <i class="number">2</i>
            <h3 class="medium-title">{{ $t('Communication') }}</h3>
            <p class="small-text">
              {{ $t('La communication avec vous est primordiale. Nous expliquons clairement la situation et nous écoutons vos besoins pour vous apporter un service personnalisé.') }}
            </p>
          </article>
          <article class="timeline-col">
            <i class="number">3</i>
            <h3 class="medium-title">{{ $t("Plan d'action") }}</h3>
            <p class="small-text">
              {{ $t("Le plan d'action est démarré pour prendre le contrôle sur la situation problématique. Nous mettons tout en œuvre pour vous redonner la paix d'esprit.") }}
            </p>
          </article>
          <article class="timeline-col">
            <i class="number">4</i>
            <h3 class="medium-title">{{ $t('Suivis') }}</h3>
            <p class="small-text">
              {{ $t('Les suivis sont très importants et toujours inclus dans nos traitements et interventions. Nous allons nous assurer que vous êtes protégés à long terme.') }}
            </p>
          </article>
          <article class="timeline-col">
            <i class="number no-line">5</i>
            <h3 class="medium-title">{{ $t('Garantie') }}</h3>
            <p class="small-text">
              {{ $t('Notre travail est garanti en tout temps. Votre satisfaction est notre priorité.') }}
            </p>
          </article>
        </div>
      </div>
    </section>

    <!-- Testimonials -->
    <section id="temoignages" class="testimonials fluid-container">
      <div class="testimonials-container max-container">
        <div class="testimonials-row">
          <article class="testimonials-col">
            <SvgIcon name="testimonials" />
            <p class="regular-text">
              <span v-html="$t('Nous avions une multinationale comme fournisseur en gestion parasitaire et nous étions traités comme de numéros sans solutions à nos problèmes. Depuis que nous avons pris <strong>Gestion Parasitaire Lauzé</strong>, nous trouvons réponse à nos questions et les problèmes se règlent. Du vrai service !')"></span>
            </p>
            <h3 class="medium-title">M. Demers</h3>
            <h3 class="medium-title subtitle">Dorval</h3>
          </article>
          <article class="testimonials-col">
            <SvgIcon name="testimonials" />
            <p class="regular-text">
              {{ $t('Une entreprise orientée vers un service de qualité! Toujours courtois, professionnels et disponibles. Vous faites la différence!') }}
            </p>
            <h3 class="medium-title">M. Trudel</h3>
            <h3 class="medium-title subtitle">Montréal</h3>
          </article>
          <article class="testimonials-col">
            <SvgIcon name="testimonials" />
            <p class="regular-text">
              {{ $t('Une personne compétente qui a toujours une solution et de précieux conseils.') }}
            </p>
            <h3 class="medium-title">S. Margeson</h3>
            <h3 class="medium-title subtitle">Montréal</h3>
            <br><br>
            <p class="regular-text">
              {{ $t('Nos problèmes ont été pris en charge avec des solutions proactives et un service soutenu.') }}
            </p>
            <h3 class="medium-title">A. Goncalves</h3>
            <h3 class="medium-title subtitle">Montréal</h3>
          </article>
        </div>
      </div>
    </section>

    <!-- Split -->
    <section id="apropos" class="split fluid-container">
      <div class="split-container max-container">
        <div class="split-row">
          <div class="split-col col-left">
            <img src="@/assets/img/gestion-parasitaire-eric-lauze.jpg" alt="Gestion parasitaire Éric Lauzé">
          </div>
          <div class="split-col col-right">
            <h2 class="big-title">
              {{ $t('À propos de GPL') }}
            </h2>
            <p class="regular-text">
              {{ $t("Possédant une expérience de plus de 10 ans dans la gestion parasitaire en ayant évolué d'abord au sein de grandes compagnies, Gestion Parasitaire Lauzé s'est fondé sur une philosophie précise. L'objectif principal étant d'accorder le temps et l'attention nécessaire à chaque situation. La qualité de notre service est primordiale au même type que celle de notre clientèle.") }}
            </p>
            <a href="#" class="cta" @click.prevent="togglePanel(!panelIsOpen)" >{{ $t('Demandez une soumission') }}</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Logo from '@/components/Logo'
import SvgIcon from '@/components/SvgIcon'

export default {
  head() {
    return {
      title: {
        inner: this.$t("L'expert en gestion parasitaire")
      },
      meta: [
        {
          name: 'description',
          content: this.$t("La gestion parasitaire et l'extermination sont notre spécialité depuis plus de 10 ans. Les méthodes utilisées et la rigueur de notre travail est à la base de la satisfaction de nos clients."),
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: this.$t("L'expert en gestion parasitaire") },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: this.$t("La gestion parasitaire et l'extermination sont notre spécialité depuis plus de 10 ans. Les méthodes utilisées et la rigueur de notre travail est à la base de la satisfaction de nos clients.")
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t("L'expert en gestion parasitaire") },
        { property: 'og:site_name', content: this.$t("L'expert en gestion parasitaire") },
        {
          property: 'og:description',
          content: this.$t("La gestion parasitaire et l'extermination sont notre spécialité depuis plus de 10 ans. Les méthodes utilisées et la rigueur de notre travail est à la base de la satisfaction de nos clients.")
        },
        // Og Image
        {
          p: 'og:image',
          c:
            'https://gplauze.com/img/gestion-parasitaire-lauze.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://gplauze.com/img/gestion-parasitaire-lauze.jpg'
        }
      ]
    }
  },
  components: { Logo, SvgIcon },
  methods: {
    ...mapActions('app', ['togglePanel'])
  },
  computed: {
    ...mapState('app', ['appTitle', 'panelIsOpen'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.hero {
  position: relative;
  overflow: hidden;
  background: $colorAccent3;

  .img-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    background-color: rgba(#000, 0.02325);

    @media (max-width: 1023px) {
      top: 0;
      left: 0;
      right: auto;
      width: 100%;
      height: 50vw;
    }

    @media (max-width: 545px) {
      width: 100%;
      height: 275px;
      margin-top: -25px;
    }

    img {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: right;

      @media (max-width: 1023px) {
        object-fit: cover;
        object-position: top;
      }

      @media (max-width: 545px) {
        object-position: center;
      }
    }
  }

  .hero-row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-direction: column-reverse;
    }
  }

  .col-left {
    width: 50%;
    padding-top: 100px;
    padding-bottom: 100px;

    @media (max-width: 1170px) {
      width: 45%;
    }

    @media (max-width: 1023px) {
      margin-top: 50vw;
      width: 100%;
      padding: 25px 0 45px 0;
    }

    @media (max-width: 545px) {
      margin-top: 225px;
    }
  }

  .logo {
    display: block;
    width: 100%;
    max-width: 245px;
    height: auto;
    margin-top: 25px;
    margin-bottom: 25px;

    @media (max-width: 1023px) {
      max-width: 180px;
      height: auto;
      margin: 25px 0;
    }
  }

  .big-title {
    display: block;
    width: 100%;
    max-width: 440px;
    margin-bottom: 15px;

    @media (max-width: 1023px) {
      max-width: 300px;
    }
  }

  .regular-text {
    display: block;
    width: 100%;
    max-width: 440px;
    margin-bottom: 25px;
  }

  .col-right {
    position: relative;
    width: 50%;

    @media (max-width: 1170px) {
      width: 55%;
    }

    @media (max-width: 1023px) {
      width: 100%;
    }

    .img-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    img {
      opacity: 0;
    }
  }
}

.sub-hero {
  background: $colorAccent1;

  .sub-hero-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 35px 0;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      padding: 0 0;
      padding-top: 15px;
      padding-bottom: 0;
    }

    .sub-hero-col {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: auto;

      @media (max-width: 767px) {
        width: 50%;
      }
    }

    svg {
      display: block;
      width: 100%;
      max-width: 45px;
      margin-right: 15px;

      @media (max-width: 767px) {
        max-width: 24px;
        margin-right: 10px;
      }
    }

    .medium-title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #fff;

      @media (max-width: 767px) {
        font-family: $fontText;
        font-size: 14px;
        width: 100%;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.items-list {
  .items-list-row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @media (max-width: 699px) {
      flex-direction: column;
    }
  }

  .col-left {
    width: 50%;
    padding-top: 100px;
    padding-bottom: 0;

    @media (max-width: 699px) {
      width: 100%;
      padding-top: 45px;
    }

    .big-title {
      margin-bottom: 20px;
    }
  }

  .col-right {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 50%;
    padding-top: 100px;
    padding-bottom: 0;

    @media (max-width: 699px) {
      width: 100%;
      padding-top: 25px;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .icon {
      position: absolute;
      top: 100px;
      right: 0;

      display: block;
      width: 100%;
      max-width: 55px;

      @media (max-width: 699px) {
        top: 0;
        max-width: 45px;
      }
    }
  }

  ul {
    width: 100%;
    margin: 0;
    padding: 40px 0;
    list-style-type: none;

    display: flex;
    align-items: stretch;
    justify-content: space-between;

    flex-wrap: wrap;

    li {
      width: calc(99% / 3);
      margin-bottom: 4px;

      @media (max-width: 699px) {
        width: calc(99% / 2);
      }

      svg {
        width: 100%;
        max-width: 24px;
        height: auto;
      }
    }
  }

  .small-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $colorAccent1;
    background: $colorAccent3;
    padding: 10px 10px;

    @media (max-width: 699px) {
      font-family: $fontText;
      font-size: 12px;
    }

    span {
      display: inline-block;
      padding: 0 10px;
    }
  }
}

.thumbs-list {
  padding-bottom: 100px;

  @media (max-width: 699px) {
    padding-bottom: 0;
  }

  .thumbs-list-row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @media (max-width: 699px) {
      flex-direction: column;
    }
  }

  .col-left {
    width: 50%;
    padding-top: 100px;
    padding-bottom: 0;

    @media (max-width: 699px) {
      width: 100%;
      padding-top: 0;
    }

    .big-title {
      margin-bottom: 20px;
    }
  }

  .col-right {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 50%;
    padding-top: 100px;
    padding-bottom: 0;

    @media (max-width: 699px) {
      width: 100%;
      padding-top: 25px;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .icon {
      position: absolute;
      top: 100px;
      right: 0;

      display: block;
      width: 100%;
      max-width: 75px;

      @media (max-width: 699px) {
        top: 0;
        max-width: 55px;
      }
    }
  }

  ul {
    width: 100%;
    margin: 0;
    padding: 40px 0;
    list-style-type: none;

    display: flex;
    align-items: stretch;
    justify-content: space-between;

    flex-wrap: wrap;

    @media (max-width: 699px) {
      position: relative;
      left: -20px;
      padding-left: 20px;
      width: calc(100% + 40px);
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
    }

    li {
      position: relative;
      width: calc(98% / 6);
      margin-bottom: 4px;

      @media (max-width: 699px) {
        min-width: 200px;
        margin-right: 5%;
      }

      .img-wrapper {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba($colorAccent1, 0.3);
          mix-blend-mode: lighten;
          z-index: 1;
        }
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .small-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $colorAccent1;
    padding: 15px 0;
    font-size: 22px;

    span {
      display: inline-block;
      padding: 0 10px;
    }
  }
}

.timeline {
  padding-top: 100px;
  padding-bottom: 100px;
  background: $colorAccent1;

  @media (max-width: 699px) {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .big-title {
    margin-bottom: 75px;
    color: #fff;

    @media (max-width: 699px) {
      margin-bottom: 25px;
    }
  }

  .medium-title {
    margin-bottom: 15px;
    color: #fff;
    text-transform: uppercase;
  }

  .timeline-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 699px) {
      position: relative;
      left: -20px;
      padding-left: 20px;
      padding-top: 0;
      padding-bottom: 0;
      width: calc(100% + 40px);
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
    }

    .timeline-col {
      position: relative;
      overflow: hidden;
      width: calc(100% / 5);
      padding-right: 40px;
      color: #fff;

      @media (max-width: 1024px) {
        min-width: 150px;
        padding-right: 20px;
      }

      @media (max-width: 699px) {
        min-width: 235px;
        padding-right: 40px;
      }
    }

    .number {
      display: block;
      width: 60px;
      height: 60px;
      margin-bottom: 15px;
      font-family: $fontTitle;
      font-size: 33px;
      border: 3px solid #fff;
      line-height: 58px;
      border-radius: 60px;
      text-align: center;

      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 100%;
        width: 500px;
        height: 3px;
        background-color: #fff;
        z-index: 1;
      }

      &.no-line {
        &:before {
          display: none;
        }
      }
    }

    .small-text {
      color: #fff;

      @media (max-width: 1024px) {

      }
    }
  }
}

.testimonials {
  padding-top: 145px;
  padding-bottom: 50px;

  @media (max-width: 699px) {
    padding-top: 45px;
  }

  .testimonials-row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @media (max-width: 699px) {
      position: relative;
      left: -20px;
      padding-left: 20px;
      padding-top: 25px;
      padding-bottom: 25px;
      width: calc(100% + 40px);
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
    }

    .testimonials-col {
      position: relative;
      width: calc(98% / 3);
      padding: 45px;
      background: $colorAccent3;

      @media (max-width: 1024px) {
        padding: 20px;
      }

      @media (max-width: 699px) {
        min-width: 275px;
        margin-right: 5%;
      }

      svg {
        display: block;
        width: 100%;
        max-width: 74px;
        margin-top: -80px;
        margin-bottom: 35px;

        @media (max-width: 699px) {
          max-width: 54px;
          margin-top: -40px;
          margin-bottom: 0;
        }
      }
    }

    .regular-text {
      margin-bottom: 30px;
    }

    .medium-title {
      text-transform: uppercase;

      &.subtitle {
        color: #575A5B;
      }
    }
  }
}

.split-row {
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 699px) {
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: column;
  }

  .col-left {
    position: relative;
    width: 50%;
    padding: 0;

    @media (max-width: 699px) {
      width: 100%;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($colorAccent1, 0.3);
      mix-blend-mode: lighten;
      z-index: 1;
    }
  }

  .col-right {
    width: 50%;
    padding: 55px;

    @media (max-width: 699px) {
      width: 100%;
      padding: 45px 0;
    }
  }

  .big-title {
    margin-bottom: 20px;
  }

  .regular-text {
    margin-bottom: 20px;
  }
}
</style>
