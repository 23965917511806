<template>
  <!-- Nav -->
  <header class="nav fluid-container">
    <div class="nav-container max-container">
      <div class="nav-row">
        <div class="nav-col col-left">
          <LogoSmall />
        </div>
        <nav class="nav-col col-right">
          <a href="tel:5149937740" class="phone">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="#1C75BC" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
            </svg>
          </a>
          <a v-if="$route.meta.lang == 'fr'" href="tel:5149937740" class="medium-title phone-number">514-993-7740</a>
          <a v-else href="tel:5149937740" class="medium-title phone-number">514-993-7740</a>
          <a href="#" class="cta" @click.prevent="togglePanel(!panelIsOpen)">{{ $t('Demandez une soumission') }}</a>
          <a v-if="$route.meta.lang == 'fr'" href="/en" class="medium-title lang"><span class="spacing">|</span>EN</a>
          <a v-else href="/" class="medium-title lang"><span class="spacing">|</span>FR</a>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import LogoSmall from '@/components/LogoSmall'

export default {
  components: { LogoSmall },
  methods: {
    ...mapActions('app', ['togglePanel'])
  },
  computed: {
    ...mapState('app', ['appTitle', 'panelIsOpen'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
  border-bottom: 1px solid rgba(#000, 0.0525);
}

.nav-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;

  color: $colorAccent1;

  @media (max-width: 545px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.medium-title {
  padding: 0 20px;
}

.lang {
  @media (max-width: 545px) {
    font-size: 16px;
  }
}

.spacing {
  padding-right: 14px;

  @media (max-width: 545px) {
    display: none;
  }
}

.phone {
  display: none;
  margin-right: 10px;
  @media (max-width: 545px) {
    display: block;
  }
}

.phone-number {
  @media (max-width: 545px) {
    display: none;
  }
}

.col-right {
  @media (max-width: 545px) {
    display: flex;
    align-items: center;
  }
}

.logo {
  @media (max-width: 545px) {
    display: block;
    width: 100%;
    max-width: 55px;
  }
}

</style>
