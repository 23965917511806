<template>
  <div class="side-panel" :class="{ showpanel: panelIsOpen }">
    <div class="panel">
      <p class="font-black">{{ $t('Demandez une soumission') }}</p>
      <br />
      <form
        :action="$route.meta.lang === 'fr' ? '/success' : '/en/success'"
        name="soumissions"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="b-address-ot-field"
      >
        <input type="hidden" name="form-name" value="soumissions" />

        <p class="b-address-ot-field">
          <label>
            {{ $t('Vous devez ignorer ce champs pour protection du formualre. Simplement laisser vide. Merci') }} : <input name="b-address-ot-field" tabindex="-1" />
          </label>
        </p>

        <input type="text" :placeholder="$t('* Prénom et nom')" name="nom" required />
        <input type="email" :placeholder="$t('* Courriel')" name="courriel" required />
        <input type="text" :placeholder="$t('* Téléphone')" name="telephone" required />
        <input type="text" :placeholder="$t('Ville')" name="ville" />

        <input type="hidden" name="customSource" value="" />
        <input type="hidden" name="lang" :value="$route.meta.lang" />

        <textarea :placeholder="$t('Message')" name="message"></textarea>

        <input type="submit" :value="$t('Envoyer')" />
      </form>
      <img
        src="@/assets/img/close.svg"
        class="close"
        alt="close"
        @click.prevent="togglePanel(!panelIsOpen)"
      />
    </div>
    <div class="overlay" @click.prevent="togglePanel(!panelIsOpen)"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  watch: {
    $route: {
      handler($route) {
        // We have a tracker query in URL
        if ($route && $route.query && $route.query.customSource) {
          setTimeout(() => {
            // Set it
            document.querySelector(
              '[name="customSource"]'
            ).value = `${$route.query.customSource}`
            // And register tracker
            localStorage.setItem('customSourceValue', `${$route.query.customSource}`)
            localStorage.setItem('customSourceDate', `${new Date().toISOString().slice(0, 10)}`)
          }, 1755)
        } else if($route && !$route.query.customSource) {
          // We do not have any tracker in URL
          // Do we have a registerd tracker on user ?
          // Is this tracker smaller or egual to than 14 days old ?
          const date1 = new Date(localStorage.getItem('customSourceDate'))
          const date2 = new Date()
          const diffInTime = date2.getTime() - date1.getTime()
          const diffInDays = diffInTime / (1000 * 3600 * 24)
          if (
            localStorage.getItem('customSourceValue') && 
            localStorage.getItem('customSourceDate') &&
            diffInDays <= 14
          ) {
            setTimeout(() => {
              // Set it
              document.querySelector(
                '[name="customSource"]'
              ).value = `${localStorage.getItem('customSourceValue')}`
            }, 1755)
          }
        }
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('app', ['togglePanel'])
  },

  computed: {
    ...mapState('app', ['panelIsOpen'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.b-address-ot-field {
  position: absolute;
  top: -9527px;
  left: -9256px;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
}

.side-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.2s ease;

  &.showpanel {
    z-index: 20000;

    .overlay {
      opacity: 0.5;
    }

    .panel {
      right: 0;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $colorAccent4;
  opacity: 0;
  z-index: 20000;
  transition: all 0.2s ease;
}

.panel {
  position: fixed;
  top: 0;
  right: -35vw;
  height: 100vh;
  width: 35vw;
  background-color: $colorAccent1;
  z-index: 30000;
  padding: 4vw 3vw;
  transition: all 0.2s ease;

  .close {
    position: absolute;
    top: 2vw;
    right: 1.5vw;
    width: 15px;
    cursor: pointer;
  }

  .font-black {
    font-family: $fontTitle;
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 20px;
    letter-spacing: 1px;
  }

  .regular-text {
    margin-bottom: 20px;
    span {
      color: #fff;
    }
  }

  form {
    input[type='text'],
    input[type='email'] {
      width: 100%;
      height: 50px;
      border: 0;
      background-color: rgba(255, 255, 255, 0.1);
      padding: 10px;
      font-family: $fontText;
      font-weight: 500;
      font-style: normal;
      font-size: 14px;
      color: #fff;
      margin-bottom: 10px;
      border-radius: 3px;
      -webkit-appearance: none;

      &::placeholder {
        color: $colorAccent4;
      }
    }
    textarea {
      width: 100%;
      height: 150px;
      max-width: 100%;
      min-width: 100%;
      max-height: 150px;
      min-height: 150px;
      border: 0;
      background-color: rgba(255, 255, 255, 0.1);
      padding: 10px;
      font-family: $fontText;
      font-weight: 500;
      font-style: normal;
      font-size: 14px;
      color: #fff;
      margin-bottom: 10px;
      border-radius: 3px;
      -webkit-appearance: none;

      &::placeholder {
        color: $colorAccent4;
      }
    }

    input[type='submit'] {
      display: inline-block;
      background-color: $colorAccent4;
      color: $colorAccent1;
      text-transform: uppercase;
      font-family: $fontText;
      font-weight: 700;
      font-style: normal;
      font-size: 14px;
      line-height: 1;
      padding: 18px 30px;
      border: none;
      border-radius: 3px;
      text-align: center;
      transition: all 0.2s ease;
      cursor: pointer;
      margin-top: 1vw;
      -webkit-appearance: none;

      &:hover {
        background-color: rgba(#000, 0.125);
        color: $colorAccent4;
      }
    }
  }

  @media (max-width: 1100px) {
    width: 40vw;
    right: -40vw;
  }
  @media (max-width: 900px) {
    width: 50vw;
    right: -50vw;
  }
  @media (max-width: 650px) {
    width: 65vw;
    right: -65vw;
  }
  @media (max-width: 500px) {
    width: 100%;
    right: -100%;
    padding: 10vw 8vw;

    .close {
      top: 5vw;
      right: 5vw;
    }

    form {
      textarea {
        height: 100px;
        max-height: 100px;
        min-height: 100px;
      }
    }
  }
}
</style>
