<template>
  <div id="app">
    <nav-bar></nav-bar>
    <main role="main" class="main-wrapper">
      <router-view />
    </main>
    <!-- SubContent -->
    <section class="sub-content fluid-container">
      <div class="sub-content-container max-container">
        <SvgIcon name="bug-white" />
        <div class="sub-content-row big-title">
          <span v-html="$t('La solution à long terme <br> contre les parasites')"></span>
        </div>
        <a href="#" class="cta" @click.prevent="togglePanel(!panelIsOpen)" >{{ $t('Demandez une soumission') }}</a>
      </div>
    </section>
    <!-- Footer -->
    <footer class="footer fluid-container">
      <div class="footer-container max-container">
        <div class="footer-row">
          <p class="copy regular-text">&copy;&nbsp;Gestion Parasitaire Lauzé - {{ $t('Tous droits réservés') }}</p>
        </div>
      </div>
    </footer>
    <!-- SideForm -->
    <SideForm />
  </div>
</template>
<script>
import { isNil } from 'lodash'

import { mapState, mapActions } from 'vuex'

import NavBar from '@/components/NavBar'
import SvgIcon from '@/components/SvgIcon'
import SideForm from '@/components/SideForm'

export default {
  watch: {
    $route: {
      handler($route) {
        if (!isNil($route)) {
          if ($route.meta.lang === 'en') {
            this.$i18n.locale = 'en'
            document.documentElement.setAttribute('lang', 'en')
          } else {
            this.$i18n.locale = 'fr'
            document.documentElement.setAttribute('lang', 'fr')
          }
        }
      },
      immediate: true
    }
  },
  components: { NavBar, SvgIcon, SideForm },
  methods: {
    ...mapActions('app', ['togglePanel'])
  },
  computed: {
    ...mapState('app', ['appTitle', 'panelIsOpen'])
  }
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';
@import '@/theme/reset.scss';
@import '@/theme/base.scss';
@import '@/theme/typography.scss';
@import '@/theme/buttons.scss';
@import '@/theme/form.scss';


#app {
  padding-top: 62px;
}

.sub-content {
  background: $colorAccent1;
  text-align: center;

  padding-top: 75px;
  padding-bottom: 75px;

  @media (max-width: 699px) {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  svg {
    @media (max-width: 699px) {
      display: block;
      max-width: 75px;
      margin: 0 auto;
    }
  }

  .big-title {
    width: 100%;
    max-width: 545px;
    padding: 35px 0;
    margin: 0 auto;
    color: #fff;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 25px;

  @media (max-width: 767px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .copy {
    text-align: center;
    line-height: 1;
  }
}
</style>
