<template>
  <svg class="logo" width="84" height="53" viewBox="0 0 84 53" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.78 46.417V5.802h37.569V0H0v52.22h43.349V34.813h-20.23v5.802h14.45v5.802H5.779z" fill="#1C75BC"/><path d="M11.56 11.604v5.803h26.009v5.802h-26.01v17.406h5.78V29.011h26.01V11.604h-31.79zM54.908 46.417V0h-5.78v52.22H84v-5.803H54.908z" fill="#1C75BC"/></svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
