const trans = {
  en: {
    "L'expert en gestion parasitaire": "The pest control expert",
    "La gestion parasitaire et l'extermination sont notre spécialité depuis plus de 10 ans. Les méthodes utilisées et la rigueur de notre travail est à la base de la satisfaction de nos clients.": "Pest control and extermination have been our specialty for over 10 years. The methods used and the rigor of our work is the basis of the satisfaction of our customers.",

    "Gardez les visiteurs indésirables à l'extérieur": "Keep unwanted visitors outside",
    "Lorsqu'un problème parasitaire se présente, nous établissons rapidement un plan d'action. Nous pouvons ensuite mettre en place un programme de prévention qui empêchera le retour d'une situation problématique. Nos méthodes vont au-delà d'une simple extermination.": "When a pest problem arises, we quickly establish an action plan. We can then set up a program that will prevent the return of a problematic situation. Our methods go beyond simple extermination.",

    "À propos de GPL": "About GPL",
    "Possédant une expérience de plus de 10 ans dans la gestion parasitaire en ayant évolué d'abord au sein de grandes compagnies, Gestion Parasitaire Lauzé s'est fondé sur une philosophie précise. L'objectif principal étant d'accorder le temps et l'attention nécessaire à chaque situation. La qualité de notre service est primordiale au même type que celle de notre clientèle.": "With more than 10 years of experience in pest control having first evolved within large companies, Gestion Parasitaire Lauzé is based on a specific philosophy. The main objective is to devote the time and attention needed to each situation. The quality of our service is essential to the same extent as that of our clientele.",

    "Nous avions une multinationale comme fournisseur en gestion parasitaire et nous étions traités comme de numéros sans solutions à nos problèmes. Depuis que nous avons pris <strong>Gestion Parasitaire Lauzé</strong>, nous trouvons réponse à nos questions et les problèmes se règlent. Du vrai service !": "We had a multinational as a supplier in pest control and we were treated like numbers without solutions to our problems. Since we took <strong>Gestion Parasitaire Lauzé</strong>, we find answers to our questions and the problems are solved. Real service!",

    "Une entreprise orientée vers un service de qualité! Toujours courtois, professionnels et disponibles. Vous faites la différence!": "A company oriented towards quality service! Always courteous, professional and available. You make the difference!",

    "Une personne compétente qui a toujours une solution et de précieux conseils.": "A competent person who always has a solution and valuable advice.",

    "Nos problèmes ont été pris en charge avec des solutions proactives et un service soutenu.": "Our issues were taken care of with proactive solutions and ongoing service.",

    "Contrôle des rongeurs": "Rodent control",
    "Contrôle des punaises de lit": "Bed bug control",
    "Contrôle des guêpes": "Wasp control",
    "Contrôle des oiseaux": "Bird control",
    "Contrôle des fourmis": "Ant control",
    "Contrôle des blattes": "Cockroach control",
    "Prévention extérieure": "Outdoor prevention",
    "Protection prolongée": "Extended protection",
    "Fumigation": "Fumigation",
    "Nos secteurs d’activités": "Our areas of activity",
    "Nous avons une solide expérience dans tous les types d'immeubles et habitations. La clé du succès de notre service est de savoir s'adapter aux environnements dans lesquels nous opérons.": "We have solid experience in all types of buildings and homes. The key to the success of our service is knowing how to adapt to the environments in which we operate.",
    "Résidentiels": "Residential",
    "Immobilier": "Real estate",
    "Restauration": "Restaurants",
    "Commercial": "Commercial",
    "Bureaux": "Offices",
    "Industriel": "Industrial",
    "Notre approche": "Our approach",
    "Inspection": "Inspection",
    "Une inspection bien effectuée est à la base de la réussite d'un traitement ou d'un programme de protection." : "A well-conducted inspection is the basis of a successful treatment or protection program.",
    "Communication": "Communication",
    "La communication avec vous est primordiale. Nous expliquons clairement la situation et nous écoutons vos besoins pour vous apporter un service personnalisé." : "Communication with you is essential. We explain the situation clearly and we listen to your needs to provide you with personalized service.",
    "Plan d'action": "Action plan",
    "Le plan d'action est démarré pour prendre le contrôle sur la situation problématique. Nous mettons tout en œuvre pour vous redonner la paix d'esprit." : "We start the action plan to gain control over the problematic situation. We do everything we can to give you peace of mind.",
    
    "Suivis": "Follow-ups",
    "Les suivis sont très importants et toujours inclus dans nos traitements et interventions. Nous allons nous assurer que vous êtes protégés à long terme." : "Follow-ups are very important and always included in our treatments. We will make sure you are protected in the long term.",

    "Garantie": "Guarantee",
    "Notre travail est garanti en tout temps. Votre satisfaction est notre priorité." : "Our work is guaranteed at all times. Your satisfaction is our priority.",

    'La solution à long terme <br> contre les parasites': 'The long-term solution <br> against parasites',
    'Demandez une soumission': 'Request a quote',
    'Tous droits réservés': 'All rights reserved',
    'Nous avons bien reçu votre message !': 'Thank you for your message!',
    'Nous vous remercions pour votre message. Nous vous répondrons dans les plus brefs délais.': ' We will answer you as soon as possible.',
    'Retour': 'Back',
    'Satisfaction garantie': 'Satisfaction guaranteed',
    'Traitements sécuritaires': 'Safe treatments',
    'Suivis minutieux': 'Continuous follow-up',
    'Estimation gratuite': 'Free estimate',
    'Vous devez ignorer ce champs pour protection du formualre. Simplement laisser vide. Merci.': 'You must ignore this field for form protection. Simply leave empty. Thank you.',

    '* Prénom et nom': '* First and last name',
    '* Courriel': '* Email',
    '* Téléphone': '* Phone',
    'Ville': 'City',
    'Message': 'Message',
    'Envoyer': 'Send',
  },
  fr: {},
}

export default trans