<template>
  <div class="page-wrapper">
    <!-- Hero -->
    <section class="hero fluid-container">
      <div class="hero-container max-container">
        <div class="hero-row">
          <div class="hero-col col-left">
            <Logo />
            <svg xmlns="http://www.w3.org/2000/svg" class="icon-tabler-circle-check" width="125" height="125" viewBox="0 0 24 24" stroke-width="1" stroke="#1abc9c" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <circle cx="12" cy="12" r="9" />
              <path d="M9 12l2 2l4 -4" />
            </svg>
            <h2 class="big-title">
              {{ $t('Nous avons bien reçu votre message !') }}
            </h2>
            <p class="regular-text">
              {{ $t('Nous vous remercions pour votre message. Nous vous répondrons dans les plus brefs délais.') }}
            </p>
            <a v-if="$route.meta.lang === 'fr'" href="/" class="cta" >{{ $t('Retour') }}</a>
            <a v-else href="/en" class="cta" >{{ $t('Retour') }}</a>
          </div>
          <div class="hero-col col-right">
            <div class="img-wrapper"><img src="@/assets/img/gestion-parasitaire-lauze.jpg" alt="Gestion parasitaire Lauzé"></div>
          </div>
        </div>
      </div>
      <div class="img-cover"><img src="@/assets/img/gestion-parasitaire-lauze.jpg" alt="Gestion parasitaire Lauzé"></div>
    </section>

    <!-- SubHero -->
    <section class="sub-hero fluid-container">
      <div class="sub-hero-container max-container">
        <div class="sub-hero-row">
          <div class="sub-hero-col">
            <h3 class="medium-title">
              <SvgIcon name="check" />
              <span>{{ $t('Satisfaction garantie') }}</span>
            </h3>
          </div>
          <div class="sub-hero-col">
            <h3 class="medium-title">
              <SvgIcon name="shield" />
              <span>{{ $t('Traitements sécuritaires') }}</span>
            </h3>
          </div>
          <div class="sub-hero-col">
            <h3 class="medium-title">
              <SvgIcon name="magnify" />
              <span>{{ $t('Suivis minutieux') }}</span>
            </h3>
          </div>
          <div class="sub-hero-col">
            <h3 class="medium-title">
              <SvgIcon name="money" />
              <span>{{ $t('Estimation gratuite') }}</span>
            </h3>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Logo from '@/components/Logo'
import SvgIcon from '@/components/SvgIcon'

export default {
  head() {
    return {
      title: {
        inner: this.$t('Nous avons bien reçu votre message !'),
      },
      meta: [
        {
          name: 'description',
          content: this.$t('Nous vous remercions pour votre message. Nous vous répondrons dans les plus brefs délais.'),
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: this.$t('Nous avons bien reçu votre message !') },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: this.$t('Nous vous remercions pour votre message. Nous vous répondrons dans les plus brefs délais.')
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t('Nous avons bien reçu votre message !') },
        { property: 'og:site_name', content: "L'expert en gestion parasitaire" },
        {
          property: 'og:description',
          content: this.$t('Nous vous remercions pour votre message. Nous vous répondrons dans les plus brefs délais.')
        },
        // Og Image
        {
          p: 'og:image',
          c:
            'https://gplauze.com/img/gestion-parasitaire-lauze.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://gplauze.com/img/gestion-parasitaire-lauze.jpg'
        }
      ]
    }
  },
  components: { Logo, SvgIcon },
  methods: {
    ...mapActions('app', ['togglePanel'])
  },
  computed: {
    ...mapState('app', ['appTitle', 'panelIsOpen'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.icon-tabler-circle-check {
  position: relative;
  left: -15px;
  margin-bottom: 10px;
}

.hero {
  position: relative;
  overflow: hidden;
  background: $colorAccent3;

  .img-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    background-color: rgba(#000, 0.02325);

    @media (max-width: 1023px) {
      top: 0;
      left: 0;
      right: auto;
      height: 50vw;
    }

    @media (max-width: 545px) {
      height: 275px;
      margin-top: -25px;
    }

    img {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: right;

      @media (max-width: 1023px) {
        object-fit: cover;
        object-position: top;
      }

      @media (max-width: 545px) {
        object-position: center;
      }
    }
  }

  .hero-row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-direction: column-reverse;
    }
  }

  .col-left {
    width: 50%;
    padding-top: 100px;
    padding-bottom: 100px;

    @media (max-width: 1170px) {
      width: 45%;
    }

    @media (max-width: 1023px) {
      margin-top: 50vw;
      width: 100%;
      padding: 25px 0 45px 0;
    }

    @media (max-width: 545px) {
      margin-top: 225px;
    }
  }

  .logo {
    display: block;
    width: 100%;
    max-width: 245px;
    height: auto;
    margin-top: 25px;
    margin-bottom: 25px;

    @media (max-width: 1023px) {
      max-width: 180px;
      height: auto;
      margin: 25px 0;
    }
  }

  .big-title {
    display: block;
    width: 100%;
    max-width: 440px;
    margin-bottom: 15px;

    @media (max-width: 1023px) {
      max-width: 300px;
    }
  }

  .regular-text {
    display: block;
    width: 100%;
    max-width: 440px;
    margin-bottom: 25px;
  }

  .col-right {
    position: relative;
    width: 50%;

    @media (max-width: 1170px) {
      width: 55%;
    }

    @media (max-width: 1023px) {
      width: 100%;
    }

    .img-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    img {
      opacity: 0;
    }
  }
}

.sub-hero {
  background: $colorAccent1;
  border-bottom: 1px solid rgba(#000, 0.125);

  .sub-hero-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 35px 0;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      padding: 0 0;
      padding-top: 15px;
      padding-bottom: 0;
    }

    .sub-hero-col {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: auto;

      @media (max-width: 767px) {
        width: 50%;
      }
    }

    svg {
      display: block;
      width: 100%;
      max-width: 45px;
      margin-right: 15px;

      @media (max-width: 767px) {
        max-width: 24px;
        margin-right: 10px;
      }
    }

    .medium-title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #fff;

      @media (max-width: 767px) {
        font-family: $fontText;
        font-size: 14px;
        width: 100%;
        padding: 0;
        margin: 0;
      }
    }
  }
}
</style>
