import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import Home from '@/views/Home'
import Success from '@/views/Success'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        lang: 'fr',
      },
    },
    {
      path: '/en',
      name: 'home-en',
      component: Home,
      meta: {
        lang: 'en',
      },
    },
    {
      path: '/success',
      name: 'success',
      component: Success,
      meta: {
        lang: 'fr',
      },
    },
    {
      path: '/en/success',
      name: 'success-en',
      component: Success,
      meta: {
        lang: 'en',
      },
    },
    { path: '*', redirect: '/' }
  ]
})

export default router
